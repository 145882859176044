<script>
import searchBar from '@components/searchBar'
import MassImportModal from "@components/modals/MassImportModal";
import PaginatedDataMixin from "@src/mixins/PaginatedDataMixin";
import Vue from "vue";
import PaginationRow from "@components/global/PaginationRow";


export default {
    page: {title: 'Πελάτες'},
    components: {PaginationRow, MassImportModal, searchBar },
    mixins: [PaginatedDataMixin],
    data() {
      return {
        contactData: [],
        submitted: false,
        athleteTags: [],
        showTagList: false,
        options: null,
        isExporting: false
      }
    },

    created() {

        this.api.url = "/athlete";
        Vue.set(this.api.filters, 'tagId', null);

        this.options = localStorage.getItem('layoutOptions') ? JSON.parse(localStorage.getItem('layoutOptions')) : null;
        if(!this.options){
          this.options = {athletes: {perPage: 6, view: 'card'}}
          localStorage.setItem('layoutOptions', JSON.stringify(this.options));
        }
        // iniitalize limti based on options!
        this.api.query.limit = this.options.athletes.perPage;

        if(this.$route.query.email){
          this.api.filters.search = this.$route.query.email;
        }
        if(this.$route.query.search){
          this.api.filters.search = this.$route.query.search;
        }
        this.getData();
        this.fetchUserAthleteTags();

    },
    methods: {
        redirectToAthlete(athleteId){
            this.$router.push({path: '/athletes/', params: {athleteId: athleteId}})
        },

        fetchUserAthleteTags(){
            this.$axios.get(`/users/athlete-tags`).then(result => {
                this.athleteTags = result.data;
            }).catch(e => {
                this.$notify({group: 'athlisis-notifications', type:'error', duration:5000, title: this.$t('errors.title'), text: 'Η φόρτωση των δεδομένων απέτυχε. Παρακαλώ δοκιμάστε ξανά!'});
            })
        },

        fetchPlansByTag(tagId){
            this.api.page = 1;
            this.api.filters.tagId = tagId;
            this.getData();
        },

        changeLayoutMode(type, value){
          if(type === 'type'){
            this.options.athletes.view = value;
          }else if(type === 'perPage'){
            this.options.athletes.perPage = parseInt(value);
            this.api.query.offset = 0;
            this.api.page = 1;
            this.getData();
          }
          // save to localStorage.
          localStorage.setItem('layoutOptions', JSON.stringify(this.options));

        },

        openCSVImportModal(){
          this.$refs['mass-import-customers-modal'].show();
        },

      exportClients(){
          this.isExporting = true;
          this.$axios.get(`/athlete/export-clients`).then( res => {
            let blob = new Blob(['\ufeff', res.data],{type: "text/csv;charset=utf-8;"});

            const filename = 'customer_export'+(new Date()).toISOString()+'.csv';

            if (window.navigator.msSaveOrOpenBlob) {
              window.navigator.msSaveBlob(blob, filename);
            }else{
              let a = document.createElement("a"),
              url = URL.createObjectURL(blob);
              a.href = url;
              a.download = filename;
              document.body.appendChild(a);
              a.click();
              setTimeout(function() {
                document.body.removeChild(a);
                window.URL.revokeObjectURL(url);
              }, 0);
            }
            this.isExporting = false;
          }).catch(e => {console.log(e);this.isExporting = false;})
      }
    },
}
</script>
<style lang="scss">
#athlete-options-form{

  @media(max-width:767px){
    display:block;
    .layout-switch{
      margin-bottom:20px;
      width:50%;
    }
    #select-athletes-per-page{
      width:100%;
      margin-bottom:20px;
      margin-left:0!important;
      margin-right:0!important;
    }
    .form-control{
      width:100%;
      margin-bottom:20px;
    }
  }
}
.choose-from-tags{
    .tag-btn{
        padding:0.3rem 0.6rem !important;
        margin-right: 7px;
        margin-bottom:7px;
    }
    .active-tag-btn{
        background-color:$color_primary;
        color:#fff;
    }
}
.card-wrapper{

  .athlete-box{
    margin:1em 0;
    padding:3em 0;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 8px 40px #E8EDF1B3;
    border-radius: 19px;
    cursor:pointer;
    &:hover{
      box-shadow: 0 0.75rem 6rem rgba(56, 65, 74, 0.2);
      transform: scale(1.03);
    }
  }
}

.athlete-table-wrapper{
  margin-top:5px;
  .athlete-box {
    cursor: pointer;
    &:hover{
      box-shadow: 0 0.75rem 6rem rgba(56, 65, 74, 0.2);
      transform: scale(1.01);
    }
  }
  .card-box{
    padding:0.5em 1em;
    margin-bottom:3px;
  }
  .usr-image{
    width:30px;
  }
  .text-muted{
    font-weight:bold;
    color:#000;
    font-size:0.8em;
  }
  @media(max-width:767px){
    .col-md-1{
      &.avatar-col{
        width:100%;
      }
      margin-bottom:10px;
      width:50%;
    }
    .col-md-2{
      margin-bottom:10px;
      width:50%;
    }
  }
}

.layout-switch{
  background:transparent;
}
</style>

<template>
<div id="athlete-list-layout">
  <searchBar>
    <template v-slot:forms>
        <form id="athlete-options-form" class="form-inline">
          <div class="text-lg-right mr-md-3 mt-lg-0">
            <b-button v-b-tooltip.hover variant="light"
                      :class="options.athletes.view === 'table' ? 'active': ''" title="Προβολή πελατών σε μορφή πίνακα"
                      class="layout-switch" @click="changeLayoutMode('type','table')">
              <i class="fe-list"></i>
            </b-button>

            <b-button v-b-tooltip.hover variant="light"
                      :class="options.athletes.view === 'card' ? 'active': ''" title="Προβολή πελατών σε μορφή καρτών"
                      class="layout-switch" @click="changeLayoutMode('type', 'card')">
              <i class="fe-grid"></i>
            </b-button>

            <b-form-select id="select-athletes-per-page" v-model="api.query.limit" class="custom-select ml-2"
                           @change="changeLayoutMode('perPage', api.query.limit)">
              <b-form-select-option value='6'>6</b-form-select-option>
              <b-form-select-option value='12'>12</b-form-select-option>
              <b-form-select-option value='24'>24</b-form-select-option>
              <b-form-select-option value='36'>36</b-form-select-option>
            </b-form-select>

          </div>
            <div class="form-group"><label class="sr-only">{{ $t('search') }}</label>
                <b-form-input v-model="api.filters.search" type="search" class="form-control" :placeholder="$t('searchPlaceholder')" debounce="400" />
            </div>
            <div class="form-group mx-sm-3">
                <b-button v-if="athleteTags.length > 0" variant="light" @click="showTagList = !showTagList">Φιλτράρισμα ανά ετικέτα <i v-if="!showTagList" class="fe-arrow-down"></i> <i v-if="showTagList" class="fe-arrow-up"></i>
                </b-button>
            </div>
        </form>
    </template>
    <template v-slot:buttons>
        <div class="text-lg-right mt-3 mt-lg-0">
            <b-button variant="light mr-2" @click="$router.push('/athletes/statistics')">
                <i class="icon-pie-chart"></i>
            </b-button>
            <b-dropdown id="dropdown-dropleft" dropleft variant="light" class="mr-1">
                <template v-slot:button-content>
                    <i class="fe-settings"></i>
                </template>
                <b-dropdown-text><h5 style="font-weight:800;"><i class="fe-settings"></i> Επιλογές:</h5> </b-dropdown-text>
                <b-dropdown-item @click="$router.push({ name: 'user-tags' })"><i class="fe-tag"></i> Επεξεργασία ετικετών</b-dropdown-item>
                <b-dropdown-item @click="$router.push({ name: 'questionnaires' })"><i class="fe-help-circle"></i> Επεξεργασία ερωτηματολογίων</b-dropdown-item>
                <b-dropdown-item @click="openCSVImportModal"><i class="fe-file"></i> Μαζική εισαγωγή</b-dropdown-item>
                <b-dropdown-item @click="exportClients" :disabled="isExporting"><i class="fas fa-file-excel"></i> Εξαγωγή πελατών</b-dropdown-item>
            </b-dropdown>
            <button type="button" class="btn btn-primary mr-1" @click="$router.push('/athletes/add')" :disabled="isEducational && api.count >= 10"><i class="fe-plus-circle"></i> Προσθήκη νέου πελάτη</button>
        </div>
    </template>
  </searchBar>

  <div v-if="showTagList" class="choose-from-tags row">
      <div class="col-md-12">
          <div class="card">
              <div class="card-body">
                  <b-button variant="outline-primary" class="tag-btn" :class=" (null === api.filters.tagId) ? 'active-tag-btn' : '' "
                            @click="fetchPlansByTag(null)">Προβολή όλων</b-button>
                  <span v-for="tag in athleteTags" :key="tag.id">
                      <b-button variant="outline-primary" class="tag-btn" :class=" (tag.id === api.filters.tagId) ? 'active-tag-btn' : '' "
                                @click="fetchPlansByTag(tag.id)"><i class="fe-tag"></i> {{tag.text}}</b-button>
                  </span>
              </div>
          </div>
      </div>
  </div>


  <div v-if="api.rows.length === 0
  && !api.filters.tagId && !api.loading && api.filters.search === '' && api.page === 1">
      <div class="card dp-not-found">
          <div class="card-body text-center">
              <h2 class="headline-not-found">ΔΕΝ ΒΡΕΘΗΚΑΝ ΠΕΛΑΤΕΣ</h2>
              <p>Δεν έχετε δημιουργήσει ακόμα κάποιο πελάτη.</p>
              <b-button variant="primary" @click="$router.push({ name: 'add-athlete' })"><i class="fe-plus-circle"></i> ΠΡΟΣΘΗΚΗ ΤΟΥ ΠΡΩΤΟΥ ΣΑΣ ΠΕΛΑΤΗ</b-button>
              <div class="pt-3"><img src="@assets/images/undraw-empty.svg" class="img-elements-not-found" alt="" height="250"/></div>
          </div>
      </div>
  </div>
  <b-alert show variant="warning" v-if="isEducational && api.count >= 10">Έχετε φτάσει το όριο των 10 πελατών</b-alert>
  <div class="row" :class="options.athletes.view === 'card' ? 'card-wrapper' : 'athlete-table-wrapper'">

    <div v-for="athlete of api.rows" :key="athlete.id" :class="options.athletes.view === 'card' ? 'col-lg-4' : 'col-lg-12'">
        <div class="text-center card-box athlete-box" @click="$router.push(`/athletes/${athlete.id}`)">
            <div v-if="options.athletes.view === 'card'" class="pt-2 pb-2">
              <img class="rounded-circle" style="width:80px;height:80px;" :src="athlete.image || 'https://www.gravatar.com/avatar/1db7993cb4a4a74ce58f4a6926cec867?s=80&d=mp&r=g'"/>
                 <h4 class="mt-3"><router-link tag="a" :to="`/athletes/${athlete.id}`" class="text-primary">{{athlete.firstName}} {{athlete.lastName}}</router-link></h4>
                <p class="text-muted"><span v-if="athlete.mobileNum"><a :href="'tel:' + athlete.mobileNum" class="text-primary-muted">{{athlete.mobileNum}}</a></span><span v-else>-</span></p>
                <div class="row mt-2">
                    <div class="col-4">
                        <div class="mt-3">
                            <h4>{{athlete.dietPlanCount}}</h4>
                            <p class="mb-0 text-muted text-truncate">Διατροφές</p>
                        </div>
                    </div>
                    <div class="col-4">
                        <div class="mt-3">
                            <h4>{{athlete.measurementCount}}</h4>
                            <p class="mb-0 text-muted text-truncate">Μετρήσεις</p>
                        </div>
                    </div>
                    <div class="col-4">
                        <div class="mt-3">
                            <h4>{{athlete.finishedToDos}}/{{athlete.totalToDos}}</h4>
                            <p class="mb-0 text-muted text-truncate">Στόχοι</p>
                        </div>
                    </div>
                </div>

                <!-- end row-->
            </div>

            <div v-else class="">
              <div class="row align-items-center">
                <div class="col-md-1 avatar-col">
                  <span class="dp-name">
                     <img class="usr-image rounded-circle":src="athlete.image || 'https://www.gravatar.com/avatar/1db7993cb4a4a74ce58f4a6926cec867?s=80&d=mp&r=g'"/></span>
                </div>
                <div class="col-md-2 text-lg-left">
                  <span class="text-muted">Όνομα πελάτη</span>  <br>
                  <span class="dp-name "> <router-link tag="a" :to="`/athletes/${athlete.id}`" class="text-dark">
                    {{athlete.firstName}} {{athlete.lastName}}
                  </router-link> </span>
                </div>
                <div class="col-md-2 text-lg-left">
                  <span class="text-muted">Τηλέφωνο επικοινωνίας</span>  <br>
                  <span class="dp-name ">
                    <span v-if="athlete.mobileNum"><a :href="'tel:' + athlete.mobileNum" class="text-primary">{{athlete.mobileNum}}</a></span><span v-else>-</span>
                  </span>
                </div>
                <div class="col-md-2 text-lg-left">
                  <span class="text-muted">E-mail</span>  <br>
                  <span class="dp-name ">
                    <span v-if="athlete.email"><a :href="'mailto:' + athlete.email" class="text-primary">{{athlete.email}}</a></span><span v-else>-</span>
                  </span>
                </div>
                <div class="col-md-1 text-lg-left">
                  <span class="text-muted">Διατροφές</span>  <br>
                  <span class="dp-name ">
                    {{athlete.dietPlanCount}}
                   </span>

                </div>
                <div class="col-md-1 text-lg-left">
                  <span class="text-muted">Μετρήσεις</span>  <br>
                  <span class="dp-name ">
                    {{athlete.measurementCount}}
                   </span>

                </div>

                <div class="col-md-2 text-lg-left">
                  <span class="text-muted">Ημερομηνία δημιουργίας</span>  <br>
                  <span class="dp-name ">
                    {{formatDateWithoutHour(athlete.createdAt)}}
                  </span>
                </div>

              </div>
            </div>
            <!-- end .padding -->
        </div>
        <!-- end card-box-->
    </div>


    <pagination-row :api="api" @fetchData="getData"></pagination-row>
  </div>

<b-modal ref="mass-import-customers-modal" id="mass-import-customers-modal" title="Μαζική εισαγωγή πελατών" hide-footer>
  <mass-import-modal
      @imported="getData"
      @closeModal="$refs['mass-import-customers-modal'].hide()"/>
</b-modal>
</div>
</template>
