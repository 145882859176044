<template>

  <div id="mass-import">

    <div v-if="step === 0" class="step-0">
      <p>Με αυτή τη λειτουργία μπορείτε να εισάγετε μαζικά τους πελάτες σας ανεβάζοντας ένα CSV αρχείο.</p>
      <p>Η μορφή του αρχείου πρέπει να είναι συγκεκριμένη και ο διαχωρισμός των στοιχείων να είναι με <strong>;</strong></p>
      <p>Τα πεδία πρέπει να βρίσκονται με την σειρά <strong>Όνομα, Επώνυμο, email, ημερομηνία γέννησης, φύλο, κινητό τηλέφωνο, κόστος συνεδρίας</strong>. Εάν δεν έχετε κάποιο πεδίο,
        εισάγετε κενή εγγραφή. <br/>Μην διαγράψετε την κεφαλίδα του αρχείου.</p>

      <p>
        <a
            href="https://athlisis-new.s3.eu-north-1.amazonaws.com/athlisis-clients-import-template.csv" target="_blank" download>
          Μπορείτε να κατεβάσετε ένα πρότυπο csv προς συμπλήρωση κάνοντας κλικ εδώ
        </a>
      </p>

      <p>
        Στο φύλο, εάν ο πελάτης σας είναι άνδρας, γράψτε <strong>Α</strong> ενώ αν είναι γυναίκα γράψε <strong>Γ</strong>
      </p>

      <p style="font-size:0.9em">Η λήψη ενδέχεται να μη λειτουργεί από Safari. Δοκιμάστε με Google Chrome ή κάποιον άλλον browser.</p>

      <vue-dropzone v-show="showZone" id="dropzone" ref="uploadLogo" :options="dropzoneOptions"
                    @vdropzone-success="completed" class="mt-3 mb-3"></vue-dropzone>
    </div>

    <div v-if="step === 1" class="step-1">

      <h4>Αποτελέσματα εισαγωγής</h4>
      <p CLASS="mt-3">Η μαζική εισαγωγή των πελατών ολοκληρώθηκε επιτυχώς. </p>
      <P>Τα αποτελέσματα της εισαγωγής ήταν τα ακόλουθα:</P>
      <ul>
        <li>Συνολικοί πελάτες: {{results.total}}</li>
        <li>Πελάτες που προστέθηκαν: {{results.imported}}</li>
        <li>Πελάτες που υπήρχαν ήδη: {{results.duplicates}}</li>
        <li>Αποτυχία εισαγωγής: {{results.failedToImport}}</li>
      </ul>

      <div class="text-center">
        <b-button variant="primary" size="xs" @click="closeModal">Κλείσιμο</b-button>
      </div>

    </div>

  </div>

</template>
<script>
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'

export default {
  components: {vueDropzone: vue2Dropzone},
  data(){
    return{
      step:0,
      showZone: true,
      dropzoneOptions: {
        url: `${process.env.VUE_APP_API}/general/csv-upload`,
        maxFilesize: 1,
        headers: { "x-access-token": JSON.parse(window.localStorage.getItem('auth.token')) },
        maxFiles: 1,
        dictDefaultMessage: 'Σύρτε εδώ ένα αρχείο csv ή πατήστε πάνω στο πλαίσιο'
      },
      results: null,
    }
  },
  methods:{
    completed(file, res){
      this.$emit('imported');
      this.step++;
      this.$notify({group: 'athlisis-notifications', type:'success',title: 'Επιτυχία!', text: 'Η μαζική εισαγωγή ολοκληρώθηκε επιτυχώς!'});
      this.results = res;
    },
    closeModal(){
      this.$emit('closeModal')
    }
  }

}
</script>
